"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoanApplicationStart = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var page_path_1 = require("../../components/page_path");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var get_providers_1 = require("../../../../api/calls/loan/get_providers");
var get_branches_1 = require("../../../../api/calls/loan/get_branches");
var application_1 = require("../../../../api/calls/loan/application");
var react_router_dom_1 = require("react-router-dom");
var main_1 = require("../../../../reducers/slices/main");
var react_redux_1 = require("react-redux");
var get_loan_1 = require("../../../../api/calls/loan/get_loan");
var get_basket_1 = require("../../../../api/calls/basket/get_basket");
var functions_1 = require("../../../../constants/functions");
var disclaimer_1 = require("./components/disclaimer");
var steps_1 = require("./constants/steps");
var route_names_1 = require("../../../../constants/route_names");
var LoanApplicationStart = function () {
    var navigate = (0, react_router_dom_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var params = (0, react_router_dom_1.useParams)();
    var loan_id = params.loan_id;
    var _a = (0, react_1.useState)(false), isSaving = _a[0], setIsSaving = _a[1];
    var _b = (0, react_1.useState)(""), loanAmount = _b[0], setLoanAmount = _b[1];
    var _c = (0, react_1.useState)(""), loanTerm = _c[0], setLoanTerm = _c[1];
    var _d = (0, react_1.useState)(""), loanProvider = _d[0], setLoanProvider = _d[1];
    var _e = (0, react_1.useState)(""), loanBranch = _e[0], setLoanBranch = _e[1];
    var _f = (0, react_1.useState)([]), branches = _f[0], setBranches = _f[1];
    var _g = (0, react_1.useState)([]), providers = _g[0], setProviders = _g[1];
    var _h = (0, react_1.useState)(""), loanAmountError = _h[0], setLoanAmountError = _h[1];
    var _j = (0, react_1.useState)(""), loanTermError = _j[0], setLoanTermError = _j[1];
    var _k = (0, react_1.useState)(""), loanBranchError = _k[0], setLoanBranchError = _k[1];
    var _l = (0, react_1.useState)(""), loanProviderError = _l[0], setLoanProviderError = _l[1];
    // const [isModalOpen, setModalIsOpen] = useState<boolean>(false);
    var _m = (0, react_1.useState)(null), activeLoan = _m[0], setActiveLoan = _m[1];
    var _o = (0, react_1.useState)(false), airtimeOnly = _o[0], setAirtimeOnly = _o[1];
    var _p = (0, react_1.useState)({}), activeUser = _p[0], setActiveUser = _p[1];
    var _q = (0, react_1.useState)(""), providerName = _q[0], setProviderName = _q[1];
    var _r = (0, react_1.useState)([]), loanTerms = _r[0], setLoanTerms = _r[1];
    //Set uneditable defaults for product loan
    (0, react_1.useEffect)(function () {
        updateDetails();
    }, [activeLoan, branches, providers, loanBranch]);
    var updateDetails = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _i, providers_1, provider, _a, branches_1, branch;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!(activeLoan && (activeLoan === null || activeLoan === void 0 ? void 0 : activeLoan.is_product))) return [3 /*break*/, 2];
                    if (providers.length > 0 && !airtimeOnly) {
                        for (_i = 0, providers_1 = providers; _i < providers_1.length; _i++) {
                            provider = providers_1[_i];
                            if (provider.label.toLowerCase() == "zero2hero")
                                setLoanProvider(provider.value);
                        }
                    }
                    if (branches.length > 0 && !airtimeOnly) {
                        for (_a = 0, branches_1 = branches; _a < branches_1.length; _a++) {
                            branch = branches_1[_a];
                            if (branch.label.toLowerCase() == "sky lifestyle")
                                setLoanBranch(branch.value);
                        }
                    }
                    if (!((activeLoan === null || activeLoan === void 0 ? void 0 : activeLoan.basket_id) && loanBranch)) return [3 /*break*/, 2];
                    return [4 /*yield*/, (0, get_basket_1.get_basket)({ _id: activeLoan === null || activeLoan === void 0 ? void 0 : activeLoan.basket_id }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                            var _res;
                            var _a;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        if (!response.success) return [3 /*break*/, 2];
                                        return [4 /*yield*/, (0, functions_1.isAirtimeOnly)((_a = response === null || response === void 0 ? void 0 : response.content) === null || _a === void 0 ? void 0 : _a.product_list)];
                                    case 1:
                                        _res = _b.sent();
                                        if (!_res) {
                                            dispatch((0, main_1.setContentModal)({
                                                title: "Disclaimer",
                                                content: (react_1.default.createElement(disclaimer_1.Disclaimer, { onProceed: function () {
                                                        saveLoanDetails();
                                                    } })),
                                            }));
                                        }
                                        setAirtimeOnly(_res);
                                        return [3 /*break*/, 3];
                                    case 2:
                                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                                        _b.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); })];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    (0, react_1.useEffect)(function () {
        if (loanProvider) {
            //Get Branches
            (0, get_branches_1.getProviderBranches)({ provider_id: loanProvider }).then(function (response) {
                var _a;
                if (response.success) {
                    var tempData_1 = [];
                    (_a = response === null || response === void 0 ? void 0 : response.content) === null || _a === void 0 ? void 0 : _a.forEach(function (doc) {
                        var branchData = doc.data();
                        tempData_1.push({ label: branchData.name, value: doc.id });
                    });
                    setBranches(tempData_1);
                }
                else {
                }
            });
        }
    }, [loanProvider]);
    //Get Provider
    (0, react_1.useEffect)(function () {
        (0, get_providers_1.getProviders)().then(function (response) {
            var _a;
            if (response.success) {
                var tempData_2 = [];
                (_a = response === null || response === void 0 ? void 0 : response.content) === null || _a === void 0 ? void 0 : _a.forEach(function (doc) {
                    var providerData = doc.data();
                    tempData_2.push({ label: providerData.name, value: doc.id });
                });
                setProviders(tempData_2);
            }
            else {
            }
        });
    }, []);
    (0, react_1.useEffect)(function () {
        if (activeLoan && (activeLoan === null || activeLoan === void 0 ? void 0 : activeLoan.is_product)) {
            if (airtimeOnly) {
                setLoanTerms([{ label: "1 Month", value: "1 Month" }]);
                setLoanProvider("");
                setLoanBranch("");
                //RemoveZero2Hero from selection
                var newObjects = providers.filter(function (obj) { return obj.label !== "Zero2Hero"; });
                setProviders(newObjects);
            }
            else {
                setLoanTerms([{ label: "12 Months", value: "12 Months" }]);
            }
        }
        else {
            var newObjects = providers.filter(function (obj) { return obj.label !== "Zero2Hero"; });
            setProviders(newObjects);
            setLoanTerms([
                { label: "1 Month", value: "1 Month" },
                { label: "3 Months", value: "3 Months" },
                { label: "5 Months", value: "5 Months" },
            ]);
        }
    }, [activeLoan, airtimeOnly]);
    //Regulate Maximun and Minumun amount
    (0, react_1.useEffect)(function () {
        var amount = parseFloat(loanAmount);
        if (amount >= 100000) {
            setLoanAmount("100000");
            setLoanAmountError("Maximum loan amount is N100 000");
        }
        else if (amount <= 1) {
            setLoanAmount("1");
            setLoanAmountError("Minimum loan amount is N1");
        }
        else {
            setLoanAmountError("");
        }
    }, [loanAmount]);
    (0, react_1.useEffect)(function () {
        if (loan_id) {
            //Get Loan
            (0, get_loan_1.getLoan)({ loan_id: loan_id }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                var loanData;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!response.success) return [3 /*break*/, 4];
                            return [4 /*yield*/, response.content.data()];
                        case 1:
                            loanData = _a.sent();
                            setActiveLoan(loanData);
                            setLoanAmount(loanData === null || loanData === void 0 ? void 0 : loanData.amount);
                            setLoanTerm(loanData === null || loanData === void 0 ? void 0 : loanData.term);
                            if (!(loanData === null || loanData === void 0 ? void 0 : loanData.provider)) return [3 /*break*/, 3];
                            return [4 /*yield*/, (0, get_branches_1.getProviderBranches)({ provider_id: loanData === null || loanData === void 0 ? void 0 : loanData.provider }).then(function (response) {
                                    var _a;
                                    if (response.success) {
                                        var providerData = response.content.data();
                                        setProviderName((_a = providerData === null || providerData === void 0 ? void 0 : providerData.name) !== null && _a !== void 0 ? _a : "Not Set");
                                    }
                                })];
                        case 2:
                            _a.sent();
                            _a.label = 3;
                        case 3: return [3 /*break*/, 5];
                        case 4:
                            dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                            _a.label = 5;
                        case 5: return [2 /*return*/];
                    }
                });
            }); });
        }
    }, [loan_id]);
    //Create Loan
    var saveLoanDetails = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!validateDetails()) return [3 /*break*/, 4];
                    setIsSaving(true);
                    if (!activeLoan) return [3 /*break*/, 2];
                    return [4 /*yield*/, (0, application_1.update_loan_application)({
                            uid: loan_id,
                            agree_terms: true,
                            provider: loanProvider,
                            branch: loanBranch,
                        }).then(function (response) {
                            if (response.success) {
                                setIsSaving(false);
                                window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                });
                                navigate("".concat(route_names_1.ROUTE_LOAN_APPLICATION, "/").concat(steps_1.APP_STEP_SUMSUB, "/").concat(loan_id));
                            }
                            else {
                                dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                            }
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, (0, application_1.create_loan_application)({
                        provider: loanProvider,
                        branch: loanBranch,
                        amount: loanAmount,
                        term: loanTerm,
                        status: "DRAFT",
                        is_product: false,
                        agree_terms: true,
                        date_started: new Date(),
                    }).then(function (response) {
                        var _a;
                        if (response.success) {
                            setIsSaving(false);
                            window.scrollTo({
                                top: 0,
                                behavior: "smooth",
                            });
                            navigate("".concat(route_names_1.ROUTE_LOAN_APPLICATION, "/").concat(steps_1.APP_STEP_SUMSUB, "/").concat((_a = response === null || response === void 0 ? void 0 : response.content) === null || _a === void 0 ? void 0 : _a.uid));
                        }
                        else {
                        }
                    })];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var validateDetails = function () {
        var messageCount = 0;
        if (!loanAmount) {
            messageCount++;
            setLoanAmountError("Loan amount is required.");
        }
        if (!loanTerm) {
            messageCount++;
            setLoanTermError("Loan term is required.");
        }
        if (!loanProvider) {
            messageCount++;
            setLoanProviderError("Please select provider.");
        }
        if (!loanBranch) {
            messageCount++;
            setLoanBranchError("Please select branch.");
        }
        return messageCount ? false : true;
    };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-8" },
        react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, { className: "flex flex-col gap-8" },
            react_1.default.createElement(page_path_1.PagePath, { path: [
                    {
                        page: "/",
                        title: "Home",
                    },
                    {
                        page: "",
                        title: "".concat((activeLoan === null || activeLoan === void 0 ? void 0 : activeLoan.is_product) ? "Product" : "", " Loan Application"),
                        active: true,
                    },
                ] }),
            react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                react_1.default.createElement("div", { className: "" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat((activeLoan === null || activeLoan === void 0 ? void 0 : activeLoan.is_product) ? "Product" : "", " Loan Application"), textColor: "text-black", bold: true })),
                !airtimeOnly && (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "How much do you want to borrow?", value: loanAmount, size: "small", disabled: (activeLoan === null || activeLoan === void 0 ? void 0 : activeLoan.is_product) ? true : false, errorText: loanAmountError, startIcon: pro_solid_svg_icons_1.faMoneyBill, iconColor: "text-gray-400", onChange: function (v) {
                            setLoanAmount(v);
                        } }),
                    react_1.default.createElement(doshx_controls_web_1.DropdownControl, { options: loanTerms, errorTextColor: "text-red-500", label: "How long do you want the loan for?", size: "small", disabled: (activeLoan === null || activeLoan === void 0 ? void 0 : activeLoan.is_product) ? true : false, value: loanTerm, errorText: loanTermError, onChange: function (v) {
                            setLoanTerm(v);
                        } }))),
                react_1.default.createElement(doshx_controls_web_1.DropdownControl, { options: providers, errorTextColor: "text-red-500", label: "Choose loan provider?", size: "small", disabled: (activeLoan === null || activeLoan === void 0 ? void 0 : activeLoan.is_product) && !airtimeOnly ? true : false, value: loanProvider, errorText: loanProviderError, onChange: function (v) {
                        setLoanProvider(v);
                    } }),
                react_1.default.createElement(doshx_controls_web_1.DropdownControl, { options: branches, errorTextColor: "text-red-500", label: "Choose Branch?", size: "small", disabled: (activeLoan === null || activeLoan === void 0 ? void 0 : activeLoan.is_product) && !airtimeOnly ? true : false, value: loanBranch, errorText: loanBranchError, onChange: function (v) {
                        setLoanBranch(v);
                    } }),
                react_1.default.createElement("div", { className: "" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { size: "small", textColor: "text-black", label: "Please note: If loan provider is not selected, by default the loan application will be send to Focus Finance call centre." })),
                react_1.default.createElement("div", { className: "flex justify-center" },
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Proceed", backgroundColor: "bg-secondary", size: "small", loading: isSaving, endIcon: pro_solid_svg_icons_1.faArrowRight, onClick: function () {
                            // console.log("clicked");
                            if (validateDetails()) {
                                dispatch((0, main_1.setContentModal)({
                                    title: "Disclaimer",
                                    content: (react_1.default.createElement(disclaimer_1.Disclaimer, { onProceed: function () {
                                            saveLoanDetails();
                                        } })),
                                }));
                            }
                        } }))))));
};
exports.LoanApplicationStart = LoanApplicationStart;
