"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Disclaimer = void 0;
var react_1 = __importStar(require("react"));
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var main_1 = require("../../../../../reducers/slices/main");
var react_redux_1 = require("react-redux");
var Disclaimer = function (props) {
    var onProceed = props.onProceed;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_1.useState)(false), isSaving = _a[0], setIsSaving = _a[1];
    var _b = (0, react_1.useState)(false), terms1 = _b[0], setTerms1 = _b[1];
    var _c = (0, react_1.useState)(false), terms2 = _c[0], setTerms2 = _c[1];
    var _d = (0, react_1.useState)(false), terms3 = _d[0], setTerms3 = _d[1];
    return (react_1.default.createElement("div", { className: "w-[700px]  flex flex-col gap-4" },
        react_1.default.createElement("div", { className: "flex flex-row justify-start items-center" },
            react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { size: "medium", label: "", value: terms1, onChange: function () {
                    setTerms1(!terms1);
                } }),
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { size: "small", textColor: "text-black", label: "I am of the intent to apply for and obtain credit or services from one or more of the Credit Providers supporting Sky LifeStyle App with credit provision to their users, as listed on the App, and this will serve as mandate and authorization to supply all of my application detail to any an all such Credit or Service Providers (hereinafter referred to as the \u201CLENDER\u201D)" })),
        react_1.default.createElement("div", { className: "flex flex-row justify-start items-center" },
            react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { size: "medium", label: "", value: terms2, onChange: function () {
                    setTerms2(!terms2);
                } }),
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { size: "small", textColor: "text-black", label: "I declare that all of the information supplied by me in this application is true and correct." })),
        react_1.default.createElement("div", { className: "flex flex-row justify-start items-center" },
            react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { size: "medium", label: "", value: terms3, onChange: function () {
                    setTerms3(!terms3);
                } }),
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { size: "small", textColor: "text-black", label: "I, the applicant, agree that the \u201CLENDER\u201D is entitled to make any enquiries to verify and research any details provided by me in this application, access the files of any credit bureau to ascertain my credit profile when assessing this application and at any time during the currency of a resulting account, disclose the existence and conduct of such an account, whether still current or not, to any credit bureau or credit grantor, and/or place my name on their own marketing mailing list, provided it be removed if so be requested by me." })),
        react_1.default.createElement("div", { className: "flex justify-center" },
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Proceed", backgroundColor: "bg-secondary", size: "small", loading: isSaving, endIcon: pro_solid_svg_icons_1.faArrowRight, onClick: function () {
                    if (terms1 && terms2 && terms3) {
                        onProceed();
                        dispatch((0, main_1.setContentModal)(null));
                    }
                    else {
                        dispatch((0, main_1.setMessageModal)({
                            title: "Please agree to terms!",
                            messages: ["To proceed, you need to agree to all the terms and conditions."],
                        }));
                    }
                } }))));
};
exports.Disclaimer = Disclaimer;
