"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoanDetailsPage = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var page_path_1 = require("../components/page_path");
var react_router_dom_1 = require("react-router-dom");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var get_loan_1 = require("../../../api/calls/loan/get_loan");
var react_redux_1 = require("react-redux");
var main_1 = require("../../../reducers/slices/main");
var get_loan_comments_1 = require("../../../api/calls/loan/get_loan_comments");
var symbols_1 = require("../../../constants/symbols");
var get_provider_1 = require("../../../api/calls/loan/get_provider");
var steps_1 = require("./steps/constants/steps");
var route_names_1 = require("../../../constants/route_names");
var LoanDetailsPage = function () {
    var _a;
    var dispatch = (0, react_redux_1.useDispatch)();
    var params = (0, react_router_dom_1.useParams)();
    var loan_id = params.loan_id;
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _b = (0, react_1.useState)(true), isLoading = _b[0], setIsLoading = _b[1];
    var _c = (0, react_1.useState)(null), activeLoan = _c[0], setActiveLoan = _c[1];
    var _d = (0, react_1.useState)(null), comments = _d[0], setComments = _d[1];
    var _e = (0, react_1.useState)(null), provider = _e[0], setProvider = _e[1];
    (0, react_1.useEffect)(function () {
        if (loan_id) {
            (0, get_loan_1.getLoan)({ loan_id: loan_id }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                var loanData;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!response.success) return [3 /*break*/, 3];
                            setIsLoading(false);
                            loanData = response.content.data();
                            if (!(loanData === null || loanData === void 0 ? void 0 : loanData.provider)) return [3 /*break*/, 2];
                            return [4 /*yield*/, (0, get_provider_1.getProvider)({ provider_id: loanData === null || loanData === void 0 ? void 0 : loanData.provider }).then(function (response) {
                                    if (response.success) {
                                        var providerData = response.content.data();
                                        setProvider(providerData);
                                    }
                                })];
                        case 1:
                            _a.sent();
                            _a.label = 2;
                        case 2:
                            setActiveLoan(loanData);
                            return [3 /*break*/, 4];
                        case 3:
                            dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                            _a.label = 4;
                        case 4: return [2 /*return*/];
                    }
                });
            }); });
        }
    }, [loan_id]);
    //Get Comments
    (0, react_1.useEffect)(function () {
        //Check status and redrect
        if (activeLoan && (activeLoan === null || activeLoan === void 0 ? void 0 : activeLoan.status) == "DRAFT") {
            if (!activeLoan.agree_terms)
                navigate("".concat(route_names_1.ROUTE_LOAN_APPLICATION, "/").concat(steps_1.APP_STEP_START, "/").concat(loan_id));
            else if (!activeLoan.sumsub_complete)
                navigate("".concat(route_names_1.ROUTE_LOAN_APPLICATION, "/").concat(steps_1.APP_STEP_SUMSUB, "/").concat(loan_id));
            else if (!activeLoan.personal_complete)
                navigate("".concat(route_names_1.ROUTE_LOAN_APPLICATION, "/").concat(steps_1.APP_STEP_PERSONAL_DETAILS, "/").concat(loan_id));
            else if (!activeLoan.family_complete)
                navigate("".concat(route_names_1.ROUTE_LOAN_APPLICATION, "/").concat(steps_1.APP_STEP_FAMILY_DETAILS, "/").concat(loan_id));
            else if (!activeLoan.friend_complete)
                navigate("".concat(route_names_1.ROUTE_LOAN_APPLICATION, "/").concat(steps_1.APP_STEP_FAMILY_DETAILS, "/").concat(loan_id));
            else if (!activeLoan.employment_complete)
                navigate("".concat(route_names_1.ROUTE_LOAN_APPLICATION, "/").concat(steps_1.APP_STEP_EMPLOYMENT_DETAILS, "/").concat(loan_id));
            else if (!activeLoan.paypoint_complete)
                navigate("".concat(route_names_1.ROUTE_LOAN_APPLICATION, "/").concat(steps_1.APP_STEP_PAYPOINT_DETAILS, "/").concat(loan_id));
            else if (!activeLoan.bankDetails_complete)
                navigate("".concat(route_names_1.ROUTE_LOAN_APPLICATION, "/").concat(steps_1.APP_STEP_BANKING_DETAILS, "/").concat(loan_id));
            else if (!activeLoan.financial_complete)
                navigate("".concat(route_names_1.ROUTE_LOAN_APPLICATION, "/").concat(steps_1.APP_STEP_FINANCIAL_DETAILS, "/").concat(loan_id));
            else if (!activeLoan.docs_complete)
                navigate("".concat(route_names_1.ROUTE_LOAN_APPLICATION, "/").concat(steps_1.APP_STEP_DOCUMENTS, "/").concat(loan_id));
            else if (!activeLoan.authoriseSalary_complete)
                navigate("".concat(route_names_1.ROUTE_LOAN_APPLICATION, "/").concat(steps_1.APP_STEP_AUTHORIZATION_SALARY_DEDUCTION, "/").concat(loan_id));
            else if (!activeLoan.authoriseBank_complete)
                navigate("".concat(route_names_1.ROUTE_LOAN_APPLICATION).concat(steps_1.APP_STEP_AUTHORIZATION_ELECTRONIC_PAYMENTS, "/").concat(loan_id));
            else
                navigate("".concat(route_names_1.ROUTE_LOAN_APPLICATION, "/").concat(steps_1.APP_STEP_COMPLETE, "/").concat(loan_id));
        }
        else {
            getComments(loan_id !== null && loan_id !== void 0 ? loan_id : "");
        }
    }, [activeLoan]);
    var getComments = function (loan_id) {
        if (loan_id) {
            (0, get_loan_comments_1.getLoanComments)({ loan_id: loan_id }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (response.success) {
                        setIsLoading(false);
                        setComments(response.content);
                    }
                    else {
                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                    }
                    return [2 /*return*/];
                });
            }); });
        }
    };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-8" },
        react_1.default.createElement("div", { className: "w-full" },
            react_1.default.createElement("div", { className: "" },
                react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, { className: "flex flex-col gap-8" },
                    react_1.default.createElement(page_path_1.PagePath, { path: [
                            {
                                page: "/",
                                title: "Home",
                            },
                            {
                                page: "/loans",
                                title: "Loans",
                            },
                            {
                                page: "/",
                                title: "Details",
                            },
                            {
                                page: "",
                                title: "".concat((_a = activeLoan === null || activeLoan === void 0 ? void 0 : activeLoan.code) !== null && _a !== void 0 ? _a : ""),
                                active: true,
                            },
                        ] }),
                    react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                        react_1.default.createElement("div", { className: "flex justify-end" },
                            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "New Application", backgroundColor: "bg-primary", size: "small", onClick: function () {
                                    navigate("".concat(route_names_1.ROUTE_LOAN_APPLICATION, "/").concat(steps_1.APP_STEP_START, "/0"));
                                } })),
                        isLoading ? (react_1.default.createElement(doshx_controls_web_1.ShimmerBoxControl, null)) : (react_1.default.createElement("div", { className: "" }, activeLoan ? (react_1.default.createElement(doshx_controls_web_1.BoxControl, { className: " shadow-sm shadow-[#9f9f9f] border-none", backgroundColor: "bg-white" },
                            react_1.default.createElement("div", { className: "flex flex-col items-center gap-4" },
                                (function () {
                                    var _a;
                                    switch (activeLoan === null || activeLoan === void 0 ? void 0 : activeLoan.status) {
                                        case "APPROVED":
                                            return (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                                                react_1.default.createElement("div", { className: "flex flex-row justify-center items-center gap-2 text-green-600" },
                                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faCheckCircle, size: "2x" }),
                                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: activeLoan === null || activeLoan === void 0 ? void 0 : activeLoan.status, textColor: "text-green-600", size: "3xlarge", center: true })),
                                                react_1.default.createElement("div", { className: "flex flex-col" },
                                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "You loan was approved.", textColor: "text-black", size: "medium", center: true })),
                                                react_1.default.createElement(doshx_controls_web_1.DividerControl, null),
                                                react_1.default.createElement("div", { className: "flex flex-row gap-3" },
                                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Provider: " }),
                                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: (_a = provider === null || provider === void 0 ? void 0 : provider.name) !== null && _a !== void 0 ? _a : "", size: "medium", bold: true, center: true })),
                                                react_1.default.createElement("div", { className: "flex flex-row gap-3" },
                                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Amount: " }),
                                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: doshx_controls_web_1.Utilities.formatMoney({ symbol: symbols_1.currencySymbol, value: activeLoan === null || activeLoan === void 0 ? void 0 : activeLoan.amount, decimalPlaces: 2 }), size: "medium", bold: true, center: true })),
                                                react_1.default.createElement("div", { className: "flex flex-row gap-3" },
                                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Term: " }),
                                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: activeLoan === null || activeLoan === void 0 ? void 0 : activeLoan.term, size: "medium", bold: true, center: true })),
                                                react_1.default.createElement("div", { className: "flex flex-row gap-3" },
                                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Loan Number: " }),
                                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: activeLoan === null || activeLoan === void 0 ? void 0 : activeLoan.loan_number, size: "medium", bold: true, center: true }))));
                                            break;
                                        case "AWAIT":
                                            return (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                                                react_1.default.createElement("div", { className: "flex flex-row justify-center items-center gap-2 text-orange-600" },
                                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faQuestionCircle, size: "2x" }),
                                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "AWAITING APPROVAL", textColor: "text-orange-600", size: "3xlarge", center: true })),
                                                react_1.default.createElement("div", { className: "flex flex-col" },
                                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "You loan is being reviewed.", textColor: "text-black", size: "medium", center: true }))));
                                            break;
                                        case "DECLINED":
                                            return (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                                                react_1.default.createElement("div", { className: "flex flex-row justify-center items-center gap-2 text-red-600" },
                                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faTimesCircle, size: "2x" }),
                                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: activeLoan === null || activeLoan === void 0 ? void 0 : activeLoan.status, textColor: "text-red-600", size: "3xlarge", center: true })),
                                                react_1.default.createElement("div", { className: "flex flex-col" },
                                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Your loan application was declined.", textColor: "text-black", size: "medium", center: true }))));
                                            break;
                                        case "ARCHIVED":
                                            return (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                                                react_1.default.createElement("div", { className: "flex flex-row justify-center items-center gap-2 text-yellow-600" },
                                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faArchive, size: "2x" }),
                                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: activeLoan === null || activeLoan === void 0 ? void 0 : activeLoan.status, textColor: "text-yellow-600", size: "3xlarge", center: true })),
                                                react_1.default.createElement("div", { className: "flex flex-col" },
                                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Your loan application has been archived due to being incomplete. Please submit a new application.", textColor: "text-black", size: "medium", center: true }))));
                                            break;
                                        default:
                                            return (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                                                react_1.default.createElement("div", { className: "flex flex-row justify-center items-center gap-2 text-blue-600" },
                                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faExclamationCircle, size: "2x" })),
                                                react_1.default.createElement("div", { className: "flex flex-col" },
                                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Something went wrong", textColor: "text-black", size: "medium", center: true }))));
                                            break;
                                    }
                                })(),
                                comments.map(function (comment, index) {
                                    return (react_1.default.createElement("div", { className: "flex flex-row justify-center items-center w-full " },
                                        react_1.default.createElement("div", { key: index, className: "flex flex-col items-center gap-1" },
                                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { className: "cursor-pointer", label: doshx_controls_web_1.Utilities.formatDateTime(comment.date.seconds * 1000, "MMMM DD, YYYY H:mm"), size: "xsmall", bold: true, textColor: "text-black" }),
                                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { className: "cursor-pointer", label: comment.comment, size: "small", textColor: "text-black" }))));
                                })))) : (react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl, { size: "small" }))))))))));
};
exports.LoanDetailsPage = LoanDetailsPage;
