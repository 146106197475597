"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.sign_in = void 0;
var auth_1 = require("firebase/auth");
var firestore_1 = require("firebase/firestore");
var firebase_1 = require("../../../../firebase");
var doshx_controls_web_1 = require("doshx_controls_web");
var messages_1 = require("./constants/messages");
function sign_in(request) {
    return __awaiter(this, void 0, void 0, function () {
        var userCredential, userDocRef, userDocSnapshot, userProfile, userObj, error_1, errorMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 4, , 5]);
                    return [4 /*yield*/, (0, auth_1.signInWithEmailAndPassword)(firebase_1.auth, request.email, request.password)];
                case 1:
                    userCredential = _a.sent();
                    userDocRef = (0, firestore_1.doc)(firebase_1.firestore, "users", userCredential.user.uid);
                    return [4 /*yield*/, (0, firestore_1.updateDoc)(userDocRef, {
                            last_login: new Date(),
                        })];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, (0, firestore_1.getDoc)(userDocRef)];
                case 3:
                    userDocSnapshot = _a.sent();
                    if (userDocSnapshot.exists()) {
                        userProfile = userDocSnapshot.data();
                        //remove user password
                        delete userProfile.password;
                        userObj = {
                            is_married: userProfile === null || userProfile === void 0 ? void 0 : userProfile.is_married,
                            gender: userProfile === null || userProfile === void 0 ? void 0 : userProfile.gender,
                            auth_token: userProfile === null || userProfile === void 0 ? void 0 : userProfile.auth_token,
                            birthday: userProfile === null || userProfile === void 0 ? void 0 : userProfile.birthday,
                            get_communication: userProfile === null || userProfile === void 0 ? void 0 : userProfile.get_communication,
                            agrees_terms_and_conditions: userProfile === null || userProfile === void 0 ? void 0 : userProfile.agrees_terms_and_conditions,
                            email: userProfile === null || userProfile === void 0 ? void 0 : userProfile.email,
                            SumSubApproved: userProfile === null || userProfile === void 0 ? void 0 : userProfile.SumSubApproved,
                            cell_number: userProfile === null || userProfile === void 0 ? void 0 : userProfile.cell_number,
                            postal_address: userProfile === null || userProfile === void 0 ? void 0 : userProfile.postal_address,
                            last_login: doshx_controls_web_1.Utilities.formatDateTime((userProfile === null || userProfile === void 0 ? void 0 : userProfile.last_login.seconds) * 1000, "DD/MM/YYYY h:mm a"),
                            applicantId: userProfile === null || userProfile === void 0 ? void 0 : userProfile.applicantId,
                            marriage_type: userProfile === null || userProfile === void 0 ? void 0 : userProfile.marriage_type,
                            id_number: userProfile === null || userProfile === void 0 ? void 0 : userProfile.id_number,
                            uid: userProfile === null || userProfile === void 0 ? void 0 : userProfile.uid,
                            numberVerified: userProfile === null || userProfile === void 0 ? void 0 : userProfile.numberVerified,
                            surname: userProfile === null || userProfile === void 0 ? void 0 : userProfile.surname,
                            name: userProfile === null || userProfile === void 0 ? void 0 : userProfile.name,
                            integration_id: userProfile === null || userProfile === void 0 ? void 0 : userProfile.integration_id,
                        };
                        return [2 /*return*/, { success: true, message: "Authenticated", content: __assign({ is_loading: false }, userObj) }];
                    }
                    else {
                        return [2 /*return*/, { success: false, message: "User profile not found", content: { is_loading: false } }];
                    }
                    return [3 /*break*/, 5];
                case 4:
                    error_1 = _a.sent();
                    errorMessage = "";
                    switch (error_1 === null || error_1 === void 0 ? void 0 : error_1.code) {
                        case messages_1.INCORRECT_PASSWORD:
                            errorMessage = "The password you entered is incorrect. Please try again or reset your password if you've forgotten it.";
                            break;
                        case messages_1.USER_NOT_FOUND:
                            errorMessage = "No account found with that email, please check the email and try again.";
                            break;
                        default:
                            errorMessage = "Authentication failed, something went wrong";
                    }
                    return [2 /*return*/, { success: false, message: errorMessage, content: { is_loading: false } }];
                case 5: return [2 /*return*/];
            }
        });
    });
}
exports.sign_in = sign_in;
