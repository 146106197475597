"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PopulatedBasket = void 0;
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var cancel_basket_1 = require("../../../api/calls/basket/cancel_basket");
var remove_products_1 = require("../../../api/calls/basket/remove_products");
var update_quantity_1 = require("../../../api/calls/basket/update_quantity");
var basket_1 = require("../../../reducers/slices/basket/basket");
var get_basket_thunk_1 = require("../../../reducers/slices/basket/thunks/get_basket_thunk");
var main_1 = require("../../../reducers/slices/main");
var symbols_1 = require("../../../constants/symbols");
var get_favourite_products_1 = require("../../../api/calls/product/get_favourite_products");
var favourite_product_1 = require("../../../api/calls/product/favourite_product");
var PopulatedBasket = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var basket = (0, react_redux_1.useSelector)(function (state) { return state.basketState; }).basket;
    var _a = (0, react_1.useState)([]), selectedProducts = _a[0], setSelectedProducts = _a[1];
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _b = (0, react_1.useState)([]), favouriteProducts = _b[0], setFavouriteProducts = _b[1];
    (0, react_1.useEffect)(function () {
        init();
    }, [user]);
    var init = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(user === null || user === void 0 ? void 0 : user.uid)) return [3 /*break*/, 1];
                    (0, get_favourite_products_1.get_favourite_products)().then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!response.success) return [3 /*break*/, 2];
                                    return [4 /*yield*/, setFavouriteProducts(response.content)];
                                case 1:
                                    _a.sent();
                                    _a.label = 2;
                                case 2: return [2 /*return*/];
                            }
                        });
                    }); });
                    return [3 /*break*/, 3];
                case 1: return [4 /*yield*/, setFavouriteProducts([])];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (react_1.default.createElement("div", { className: "flex flex-col lg:flex-row gap-x-4 gap-y-8" },
        react_1.default.createElement("div", { className: "flex-grow flex flex-col gap-4" },
            react_1.default.createElement(doshx_controls_web_1.BoxControl, { className: "flex flex-row justify-between items-center" },
                react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Select all", value: selectedProducts.length === (basket === null || basket === void 0 ? void 0 : basket.product_list.length), onChange: function (v) {
                        if (v) {
                            for (var _i = 0, _a = basket.product_list; _i < _a.length; _i++) {
                                var p = _a[_i];
                                if (!selectedProducts.includes(p._id)) {
                                    selectedProducts.push(p._id);
                                }
                            }
                            setSelectedProducts(__spreadArray([], selectedProducts, true));
                        }
                        else {
                            setSelectedProducts([]);
                        }
                    } }),
                react_1.default.createElement("div", { className: "flex flex-row flex-wrap gap-8" },
                    react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: "CANCEL BASKET", textColor: "text-red-500", onClick: function () {
                            (0, cancel_basket_1.cancel_basket)({
                                _id: basket._id,
                            }).then(function (response) {
                                if (response.success) {
                                    dispatch((0, basket_1.setBasket)(null));
                                    navigate("/shop");
                                }
                                else {
                                    dispatch((0, main_1.setMessageModal)({
                                        title: "Something went wrong!",
                                        messages: [response.message],
                                    }));
                                }
                            });
                        } }),
                    react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: "REMOVE SELECTED", textColor: "text-red-500", onClick: function () {
                            if (selectedProducts && selectedProducts.length) {
                                (0, remove_products_1.remove_products)({
                                    _id: basket._id,
                                    product_id_list: selectedProducts,
                                }).then(function (response) {
                                    if (response.success) {
                                        dispatch((0, get_basket_thunk_1.get_basket_thunk)());
                                    }
                                    else {
                                        dispatch((0, main_1.setMessageModal)({
                                            title: "Something went wrong!",
                                            messages: [response.message],
                                        }));
                                    }
                                });
                            }
                            else {
                                dispatch((0, main_1.setMessageModal)({
                                    title: "Selection required.",
                                    messages: ["Please make a selection!"],
                                }));
                            }
                        } }))),
            react_1.default.createElement("div", { className: "flex flex-col pl-4" }, basket &&
                basket.product_list.map(function (product, index) {
                    var _a, _b, _c;
                    return (react_1.default.createElement("div", { key: "container-".concat(product._id) },
                        react_1.default.createElement("div", { className: "flex flex-none flex-row" },
                            react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { value: selectedProducts.includes(product._id), onChange: function () {
                                    var idx = selectedProducts.indexOf(product._id);
                                    if (selectedProducts.includes(product._id)) {
                                        selectedProducts.splice(idx, 1);
                                        setSelectedProducts(__spreadArray([], selectedProducts, true));
                                    }
                                    else {
                                        selectedProducts.push(product._id);
                                        setSelectedProducts(__spreadArray([], selectedProducts, true));
                                    }
                                    dispatch((0, basket_1.setBasket)(__assign({}, basket)));
                                } }),
                            react_1.default.createElement("div", { className: "flex-grow flex flex-row" },
                                react_1.default.createElement("div", { className: "w-[80px] h-[80px]" },
                                    react_1.default.createElement("img", { className: "w-full h-full object-cover rounded-lg", src: (_a = product.image_url) !== null && _a !== void 0 ? _a : "" })),
                                react_1.default.createElement("div", { className: "flex-1 flex flex-col gap-2" },
                                    react_1.default.createElement("div", { className: "flex-1 flex flex-col self-start pl-4" },
                                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: product.title, size: "small" }),
                                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: doshx_controls_web_1.Utilities.formatMoney({ symbol: symbols_1.currencySymbol, value: (_c = (_b = product.price) === null || _b === void 0 ? void 0 : _b.toString()) !== null && _c !== void 0 ? _c : "0" }), size: "medium", textColor: "text-primary" })),
                                    react_1.default.createElement("div", { className: "flex-grow justify-end self-end flex flex-row items-center gap-4" },
                                        react_1.default.createElement(doshx_controls_web_1.IncrementControl, { borderColor: "border-borders", key: "increment-".concat(product._id), size: "small", value: parseInt(product.quantity), max: 10, onChange: function (v) {
                                                (0, update_quantity_1.update_quantity)({
                                                    _id: basket._id,
                                                    product: {
                                                        _id: product._id,
                                                        quantity: v.toString(),
                                                    },
                                                }).then(function (response) {
                                                    if (response.success) {
                                                        dispatch((0, get_basket_thunk_1.get_basket_thunk)());
                                                    }
                                                    else {
                                                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                                                    }
                                                });
                                            } }),
                                        react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_solid_svg_icons_1.faTrash, backgroundColor: "bg-gray-200", iconColor: "text-gray-500", size: "small", onClick: function () {
                                                (0, remove_products_1.remove_products)({
                                                    _id: basket._id,
                                                    product_id_list: [product._id],
                                                }).then(function (response) {
                                                    if (response.success) {
                                                        dispatch((0, get_basket_thunk_1.get_basket_thunk)());
                                                    }
                                                    else {
                                                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                                                    }
                                                });
                                            } }),
                                        react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: (favouriteProducts === null || favouriteProducts === void 0 ? void 0 : favouriteProducts.find(function (x) { return x.pid === product.pid; })) ? pro_solid_svg_icons_1.faHeart : pro_light_svg_icons_1.faHeart, borderColor: "border-secondary", backgroundColor: (favouriteProducts === null || favouriteProducts === void 0 ? void 0 : favouriteProducts.find(function (x) { return x.pid === product.pid; })) ? "bg-secondary" : "bg-transaparent", iconColor: (favouriteProducts === null || favouriteProducts === void 0 ? void 0 : favouriteProducts.find(function (x) { return x.pid === product.pid; })) ? "text-white" : "text-red-500", size: "small", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                                return __generator(this, function (_a) {
                                                    switch (_a.label) {
                                                        case 0:
                                                            if (!(user === null || user === void 0 ? void 0 : user.uid)) return [3 /*break*/, 2];
                                                            dispatch((0, main_1.setMessageModal)({
                                                                title: "Please Wait",
                                                                messages: ["Please wait while we process your request."],
                                                            }));
                                                            return [4 /*yield*/, (0, favourite_product_1.favorite_product)({
                                                                    product_id: product === null || product === void 0 ? void 0 : product._id,
                                                                }).then(function (response) {
                                                                    if (response.success) {
                                                                        dispatch((0, main_1.setMessageModal)({
                                                                            title: "Success",
                                                                            messages: ["".concat(response === null || response === void 0 ? void 0 : response.message)],
                                                                        }));
                                                                    }
                                                                    else {
                                                                        dispatch((0, main_1.setMessageModal)({
                                                                            title: "Error",
                                                                            messages: ["".concat(response === null || response === void 0 ? void 0 : response.message)],
                                                                        }));
                                                                    }
                                                                })];
                                                        case 1:
                                                            _a.sent();
                                                            return [3 /*break*/, 3];
                                                        case 2:
                                                            dispatch((0, main_1.setDrawerPage)("login"));
                                                            dispatch((0, main_1.setDrawerOpen)(true));
                                                            _a.label = 3;
                                                        case 3: return [2 /*return*/];
                                                    }
                                                });
                                            }); } }))))),
                        index < basket.product_list.length - 1 && react_1.default.createElement(doshx_controls_web_1.DividerControl, null)));
                }))),
        react_1.default.createElement("div", { className: "lg:w-96" },
            react_1.default.createElement(doshx_controls_web_1.BoxControl, { className: "flex flex-col gap-4" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Shopping Summary", size: "large" }),
                react_1.default.createElement("div", { className: "flex flex-row justify-between" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Total" }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: doshx_controls_web_1.Utilities.formatMoney({ symbol: symbols_1.currencySymbol, value: basket === null || basket === void 0 ? void 0 : basket.total.toString(), decimalPlaces: 2 }), size: "large" })),
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Checkout", backgroundColor: "bg-primary", onClick: function () {
                        navigate("/checkout");
                    } }),
                react_1.default.createElement("div", { className: "flex flex-row justify-center" },
                    react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: "Back to Shop", onClick: function () {
                            navigate("/shop");
                        } }))))));
};
exports.PopulatedBasket = PopulatedBasket;
