"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.APP_STEP_COMPLETE = exports.APP_STEP_AUTHORIZATION_ELECTRONIC_PAYMENTS = exports.APP_STEP_AUTHORIZATION_SALARY_DEDUCTION = exports.APP_STEP_AGREEMENT_OF_SALE = exports.APP_STEP_DOCUMENTS = exports.APP_STEP_FINANCIAL_DETAILS = exports.APP_STEP_BANKING_DETAILS = exports.APP_STEP_PAYPOINT_DETAILS = exports.APP_STEP_EMPLOYMENT_DETAILS = exports.APP_STEP_FAMILY_DETAILS = exports.APP_STEP_PERSONAL_DETAILS = exports.APP_STEP_SUMSUB = exports.APP_STEP_START = void 0;
exports.APP_STEP_START = "start";
exports.APP_STEP_SUMSUB = "sumsub";
exports.APP_STEP_PERSONAL_DETAILS = "personal-details";
exports.APP_STEP_FAMILY_DETAILS = "family-details";
exports.APP_STEP_EMPLOYMENT_DETAILS = "employment-details";
exports.APP_STEP_PAYPOINT_DETAILS = "paypoint-details";
exports.APP_STEP_BANKING_DETAILS = "banking-details";
exports.APP_STEP_FINANCIAL_DETAILS = "financial-details";
exports.APP_STEP_DOCUMENTS = "documents";
exports.APP_STEP_AGREEMENT_OF_SALE = "agreement-of-sale";
exports.APP_STEP_AUTHORIZATION_SALARY_DEDUCTION = "authorization-salary-deduction";
exports.APP_STEP_AUTHORIZATION_ELECTRONIC_PAYMENTS = "authorization-electronic-payments";
exports.APP_STEP_COMPLETE = "complete";
